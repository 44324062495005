import {
  Checkbox as MaterialCheckbox,
  IconButton, MenuItem, Select as MaterialSelect,
  Table, TableBody,
  TableCell,
  TableRow,
  TextField as MaterialTextField,
  Typography,
} from '@mui/material';
import {Field} from 'react-final-form';
import React, {ChangeEvent} from 'react';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';
import AddIcon from 'spectra-logic-ui/icons/Add';
import makeStyles from '@mui/styles/makeStyles';
import {SpectraLogoColor} from 'spectra-logic-ui/colors';
import {FieldArray} from 'react-final-form-arrays';
import {SelectChangeEvent} from '@mui/material/Select';


const useStyles = makeStyles({
  textField: {
    marginTop: 12,
  },
  root: {
    '& > *': {
      paddingTop: 0,
      paddingBottom: 5,
    },
  },
  typography: {
    marginLeft: '10px',
  },
  card: {
    marginBottom: 5,
  },
  checkbox: {
    marginLeft: 0,
  },
  mtu: {
    '& > *': {
      width: '30%',
    },
  },
  plus: {
    color: SpectraLogoColor.GREEN,
  },
  div: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  errordiv: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'red',
  },
  errorTypography: {
    marginLeft: '10px',
    color: 'red',
  },
});

const entitlementTypes = new Map<string, string>([
  ['CloudControl', 'boolean'],
  ['LocalControl', 'boolean'],
  ['Endpoints', 'integer'],
  ['LinkedBuckets', 'integer'],
  ['CloudBuckets', 'integer'],
  ['TBUnderManagement', 'integer'],
]);

export const Entitlements = () => {
  const classes = useStyles();
  const dimensionList = [...entitlementTypes.keys()];

  return (
    <div>
      <FieldArray name='entitlements' className={classes.errordiv}>
        {({fields, meta: {touched, error, invalid, submitError}}) => {
          let outerStyle = '';
          let typographyStyle = classes.typography;
          if (touched && invalid && (error || submitError)) {
            outerStyle = classes.errordiv;
            typographyStyle = classes.errorTypography;
          }
          return (
            <div className={outerStyle}>
              <div className={classes.div}>
                <Typography className={typographyStyle}>
              Entitlements
                </Typography>
                <IconButton
                  className={classes.plus}
                  onClick={() => {
                    fields.push('');
                  }}
                  size='large'>
                  <AddIcon />
                </IconButton>
              </div>
              {touched && invalid && <div>
                <Typography className={classes.errorTypography}>{submitError}</Typography>
              </div>}
              <Table>
                <TableBody>
                  {fields.map((name, index) => (
                    <TableRow key={name}>
                      <Field name={name}>
                        {({input, meta: {touched, invalid}}: any) => {
                          const d = input.value['dimension'];
                          const v = input.value['value'];
                          let ret: { [key: string]: any } = {dimension: d, value: v};

                          const onTextValueChange = (event: ChangeEvent<HTMLInputElement>) => {
                            if (entitlementTypes.get(ret.dimension) == 'integer') {
                              if (!Number.isNaN(Number(event.target.value))) {
                                ret = {dimension: ret.dimension, value: Number(event.target.value)};
                                input.onChange(ret);
                              }
                            } else {
                              ret = {dimension: ret.dimension, value: event.target.value};
                              input.onChange(ret);
                            }
                          };
                          const onCheckboxValueChange = (event: ChangeEvent<HTMLInputElement>) => {
                            ret = {dimension: ret.dimension, value: event.target.checked};
                            input.onChange(ret);
                          };
                          const onDimensionChange = (event: SelectChangeEvent) => {
                            ret = {dimension: event.target.value, value: ret.value};
                            if (entitlementTypes.get(event.target.value) == 'boolean') {
                              ret.value=true;
                            }
                            if (entitlementTypes.get(event.target.value) == 'integer') {
                              ret.value='';
                            }
                            input.onChange(ret);
                          };

                          return (
                            [
                              <TableCell>
                                <MaterialSelect
                                  onChange={onDimensionChange}
                                  value={d}
                                >
                                  {dimensionList.map(function(dimension: string) {
                                    return (
                                      <MenuItem value={dimension}>{dimension}</MenuItem>
                                    );
                                  })}
                                </MaterialSelect>
                              </TableCell>,
                              <TableCell>
                                {(entitlementTypes.get(d) == 'integer') && <MaterialTextField
                                  fullWidth
                                  value={v}
                                  onChange={onTextValueChange}
                                  error={touched && invalid}
                                  helperText={touched && invalid ? ' ' : null} variant='standard'
                                />}
                                {(entitlementTypes.get(d) == 'boolean') && <MaterialCheckbox
                                  checked={v}
                                  onChange={onCheckboxValueChange}
                                />}
                              </TableCell>,
                            ]
                          );
                        }}
                      </Field>
                      <TableCell>
                        <IconButton onClick={() => fields.remove(index)} size='large'>
                          <DeleteIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                  }
                </TableBody>
              </Table>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};
