import React from 'react';
import {connect} from 'react-redux';
import {isArray} from 'chart.js/helpers';

import {Table, TableBody, TableRow, TableCell} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';

import {Product} from '../common/types';
import {fetchProductWithAssetLinks} from '../common/fetch';

type Props = {
  item: string;
  fetchProduct: (id: string) => Promise<any>;
  products: Product[];
}

const useStyles = makeStyles({
  tableRow: {
    '& > *': {
      overflowWrap: 'break-word',
    },
  },
  key: {
    width: '30%',
  },
  value: {
    width: '70%',
  },
});

const AssetLinks = ({item, fetchProduct, products}: Props) => {
  const classes = useStyles();

  React.useEffect(() => {
    if (fetchProduct !== undefined) {
      fetchProduct(item);
    }
  }, [item]);

  const product = products.find((p) => p.id === item);
  if (product === undefined) {
    return null;
  }
  if (!isArray(product.assetLinks) || product.assetLinks.length === 0) {
    return null;
  }
  return (
    <Table>
      <TableBody>
        {product.assetLinks.map((a) => (
          <TableRow key={a.name} className={classes.tableRow}>
            <TableCell className={classes.key}>{a.name}</TableCell>
            <TableCell className={classes.value}><a href={a.link}>{a.link}</a></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  return {
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProduct: (id: string) => fetchProductWithAssetLinks(id, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetLinks);
