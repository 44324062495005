import React from 'react';
import {connect} from 'react-redux';

import Grid from '@mui/material/Grid';

import isEmpty from 'is-empty';

import {Dispatch, Store} from 'spectra-logic-ui';
import Card from 'spectra-logic-ui/components/card';

import {fetchAssets} from '../common/fetch';
import ButtonToolbar from '../common/button_toolbar';
import {Asset} from '../common/types';
import Table from '../common/table';
import DeleteAsset from './delete';
import NewAsset from './new';
import EditAsset from './edit';

type Props = {
  items?: Asset[];
  error?: boolean;
  fetching?: boolean;
  fetchItems?: () => Promise<any>;
}

const Assets = ({
  items = [],
  error = false,
  fetching = false,
  fetchItems,
}: Props) => {
  const [selectedItem, setSelectedItem] = React.useState({} as Asset);
  React.useEffect(() => {
    if (fetchItems !== undefined) {
      fetchItems();
    }
  }, []);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <Card.Body>
              <ButtonToolbar>
                <ButtonToolbar.CreateDialogButton title='New Asset' dialog={NewAsset} />
                <ButtonToolbar.EditDialogButton
                  disabled={isEmpty(selectedItem)}
                  dialog={(props: any) => <EditAsset item={selectedItem} {...props} />}
                />
                <ButtonToolbar.DeleteDialogButton
                  disabled={isEmpty(selectedItem)}
                  dialog={(props: any) => (
                    <DeleteAsset item={selectedItem} {...props} />
                  )}
                />
              </ButtonToolbar>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Product Type</Table.Cell>
                    <Table.Cell>Version</Table.Cell>
                    <Table.Cell>URL</Table.Cell>
                    <Table.Cell>Branch</Table.Cell>
                    <Table.Cell>File Type</Table.Cell>
                    <Table.Cell>File Name</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body isLoading={fetching} hasError={error}>
                  {items && items.map((item) => (
                    <Table.Row
                      key={item.id} selected={item.id === selectedItem.id}
                      onClick={() => setSelectedItem(item)}
                    >
                      <Table.Cell>{item.productType}</Table.Cell>
                      <Table.Cell>{item.version}</Table.Cell>
                      <Table.Cell>{item.url}</Table.Cell>
                      <Table.Cell>{item.branch}</Table.Cell>
                      <Table.Cell>{item.fileType}</Table.Cell>
                      <Table.Cell>{item.fileName}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => {
  const items = state.resources['assets'] || {};
  return {
    items: items.data || [],
    error: items.error,
    fetching: items.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    fetchItems: () => fetchAssets(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
