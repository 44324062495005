import {connect} from 'react-redux';

import {patchResource} from 'spectra-logic-ui/actions';
import {Dispatch, Store} from 'spectra-logic-ui';

import {fetchProduct} from '../common/fetch';
import ProductForm from './form';

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  const uiState = state.ui || {};
  return {
    production: uiState['development'] ? !uiState['development'] : true,
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
    method: 'edit',
    disabledList: ['productType', 'id'],
    visibleList: [
      'productType',
      'id',
      'accounts',
      'activationKey',
      'instance',
      'data',
      'holdVersion',
      'availableVersion',
      'branch',
      'description',
      'entitlements',
    ],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: any) => {
    const {accounts='', ...otherValues} = values;
    let accountsArray = accounts.split(/[\s,]+/);
    accountsArray = accountsArray.map((a: string) => a.trim()).filter((a: string) => a != '');
    return dispatch(patchResource('products', values.id, {accounts: accountsArray, ...otherValues}));
  },
  fetchProduct: (id: string) => fetchProduct(id, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
