import React from 'react';
import {connect} from 'react-redux';
import {isArray} from 'chart.js/helpers';

import {Table, TableBody, TableRow, TableCell} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {dateTimeShort} from 'spectra-logic-ui/helpers/date';

import {Product} from '../common/types';
import {fetchProduct} from '../common/fetch';

type Props = {
  item: string;
  fetchProduct: (id: string) => Promise<any>;
  products: Product[];
  production: boolean;
}

const useStyles = makeStyles({
  tableRow: {
    '& > *': {
      overflowWrap: 'break-word',
    },
  },
  key: {
    width: '30%',
  },
  value: {
    width: '70%',
  },
});

const Properties = ({item, fetchProduct, products, production}: Props) => {
  const classes = useStyles();

  React.useEffect(() => {
    if (fetchProduct !== undefined) {
      fetchProduct(item);
    }
  }, [item]);

  const product = products.find((p) => p.id === item);
  if (product === undefined) {
    return null;
  }
  const userProps = [
    {key: 'Product Type', value: product.productType},
    {key: 'Serial Number', value: product.id},
    {key: 'Activation Key', value: product.activationKey},
    {key: 'Description', value: product.description},
    {key: 'Accounts',
      value: isArray(product.accounts) ? product.accounts.join(', '): ''},
    {key: 'Instance', value: product.instance},
    {key: 'Current Version', value: product.currentVersion},
    {key: 'Lowest Version', value: product.lowestVersion},
    {key: 'Hold Version', value: product.holdVersion},
    {key: 'Available Version', value: product.availableVersion},
    {key: 'Last Accessed', value: dateTimeShort(product.lastAccessed)},
    {key: 'Last Modified', value: dateTimeShort(product.lastModified)},
    {key: 'Public Key', value: product.publicKey},
  ];
  if (!production) {
    userProps.push({key: 'Branch', value: product.branch});
  }

  return (
    <Table>
      <TableBody>
        {userProps.map((kv) => (
          <TableRow key={kv.key} className={classes.tableRow}>
            <TableCell className={classes.key}>{kv.key}</TableCell>
            <TableCell className={classes.value}>{kv.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  const uiState = state.ui || {};
  return {
    production: uiState['development'] ? !uiState['development'] : true,
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProduct: (id: string) => fetchProduct(id, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
