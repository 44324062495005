import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {Dispatch, Store} from 'spectra-logic-ui';
import Card from 'spectra-logic-ui/components/card';

import Table from '../common/table';
import {About} from '../common/types';
import {fetchAbout} from '../common/fetch';

type Props = {
  about: About;
  error?: boolean;
  fetching?: boolean;
  fetchAbout?: () => Promise<any>;
};

const About = ({about, error=false, fetching=false, fetchAbout}: Props) => {
  useEffect(() => {
    if (fetchAbout !== undefined) {
      fetchAbout();
    }
  }, []);

  return (
    <Card>
      <Card.Body>
        <Table>
          <Table.Body isLoading={fetching} hasError={error}>
            <Table.Row><Table.Cell>Version</Table.Cell><Table.Cell>{about.version}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell>Branch</Table.Cell><Table.Cell>{about.branch}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell>Commit</Table.Cell><Table.Cell>{about.commit}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell>CloudFormation Stack</Table.Cell><Table.Cell>{about.stack}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell>Vail S3 Bucket</Table.Cell><Table.Cell>{about.vailBucket}</Table.Cell></Table.Row>
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state: Store) => {
  const about = state.resources['about'] || {};
  return {
    about: about.data || {},
    error: about.error,
    fetching: about.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    fetchAbout: () => fetchAbout(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
