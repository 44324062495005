import React from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';

import {Grid} from '@mui/material';

import {FormDialog, FormRenderProps, TextField, SingleSelect, Card} from 'spectra-logic-ui/components';

import {Asset, Version} from '../common/types';
import {Froms, Fors} from './common';
import {Dispatch, Store} from 'spectra-logic-ui';
import {fetchOss} from '../common/fetch';

type Props = {
  onSubmit: (values: Asset) => Promise<any>;
  item: Asset;
  production: boolean;
  fetchOss?: () => Promise<any>;
  versions: Version[];
  oss: string[];
  disabledList: string[];
}

const AssetForm = (
  {onSubmit, item, fetchOss, oss, production, disabledList = [], ...otherProps}: Props,
) => {
  const isDisabled = function(fieldName: string) {
    return disabledList.includes(fieldName);
  };

  return (
    <FormDialog
      title='Edit Asset'
      initialValues={item}
      onSubmit={onSubmit}
      mutators={{...arrayMutators}}
      {...otherProps}>
      {({}: FormRenderProps) => {
        React.useEffect(() => {
          if (fetchOss !== undefined) {
            fetchOss();
          }
        }, []);
        return (
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SingleSelect name='productType' label='Product Type'
                  options={['vail', 'storcycle']}
                  style={{width: '200px'}}
                  disabled={isDisabled('productType')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField name='version' label='Version'
                  disabled={isDisabled('version')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField name='url' label='URL'
                  disabled={isDisabled('url')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField name='minimumProductVersion' label='Minimum Product Version'
                  disabled={isDisabled('minimumProductVersion')}
                />
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <FieldArray
                    name='for'
                    component={Froms}
                    disabled={isDisabled('from')}
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Fors osList={oss}
                    disabled={isDisabled('for')}
                  />
                </Card>
              </Grid>
              {!production &&
                  <Grid item xs={6}>
                    <TextField name='branch' label='Branch'
                      disabled={isDisabled('branch')}
                    />
                  </Grid>
              }
              <Grid item xs={6}>
                <TextField name='fileType' label='File Type'
                  disabled={isDisabled('fileType')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField name='fileName' label='File Name'
                  disabled={isDisabled('fileName')}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </FormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  const uiState = state.ui || {};
  const oss = state.resources['os'] || {};
  return {
    production: uiState['development'] ? !uiState['development'] : true,
    oss: oss.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchOss: () => fetchOss(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetForm);
