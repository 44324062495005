import {connect} from 'react-redux';

import {patchResource} from 'spectra-logic-ui/actions';
import {Dispatch, Store} from 'spectra-logic-ui';

import {fetchProduct} from '../common/fetch';
import ProductForm from './form';

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  return {
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
    method: 'edit',
    disabledList: ['productType', 'id', 'activationKey', 'description'],
    visibleList: [
      'productType',
      'id',
      'activationKey',
      'description',
      'entitlements',
    ],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: any) => {
    // We only ever want to change the entitlements in this function
    const props = {
      entitlements: values.entitlements,
    };
    return dispatch(patchResource('products', values.id, props));
  },
  fetchProduct: (id: string) => fetchProduct(id, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
