import React from 'react';
import {connect} from 'react-redux';

import Grid from '@mui/material/Grid';

import {Dispatch, Store} from 'spectra-logic-ui';
import Card from 'spectra-logic-ui/components/card';

import {fetchBucketRoles} from '../common/fetch';
import ButtonToolbar from '../common/button_toolbar';
import {BucketRole} from '../common/types';
import Table from '../common/table';
import NewBucketRoleDialog from './new';
import isEmpty from 'is-empty';
import DeleteDialog from '../common/delete_dialog';

type Props = {
  items?: BucketRole[];
  error?: boolean;
  fetching?: boolean;
  fetchItems?: () => Promise<any>;
}

const BucketRoles = ({
  items = [],
  error = false,
  fetching = false,
  fetchItems,
}: Props) => {
  const [selectedItem, setSelectedItem] = React.useState({} as BucketRole);
  const clearItem = () => {
    setSelectedItem({} as BucketRole);
  };
  React.useEffect(() => {
    if (fetchItems !== undefined) {
      fetchItems();
    }
  }, []);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <Card.Body>
              <ButtonToolbar>
                <ButtonToolbar.CreateDialogButton dialog={NewBucketRoleDialog} />
                <ButtonToolbar.DeleteDialogButton
                  disabled={isEmpty(selectedItem)} onSuccess={clearItem}
                  dialog={(props: any) => (
                    <DeleteDialog
                      id={selectedItem.id} name={selectedItem.id} resource='bucket_role' {...props}
                    />
                  )}
                />
              </ButtonToolbar>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Bucket Name</Table.Cell>
                    <Table.Cell>Role ARN</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body isLoading={fetching} hasError={error}>
                  {items && items.map((item) => (
                    <Table.Row
                      key={item.id} selected={item.id === selectedItem.id}
                      onClick={() => setSelectedItem(item)}
                    >
                      <Table.Cell>{item.id}</Table.Cell>
                      <Table.Cell>{item.value}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => {
  const items = state.resources['bucket_role'] || {};
  return {
    items: items.data || [],
    error: items.error,
    fetching: items.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    fetchItems: () => fetchBucketRoles(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(BucketRoles);
