import React from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';

import {Grid, TextareaAutosize} from '@mui/material';

import {patchResource} from 'spectra-logic-ui/actions';
import {Card, FormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';
import {TextField} from 'spectra-logic-ui/components';

import {fetchProductWithSignedEntitlements} from '../common/fetch';
import {Product} from '../common/types';

type Props = {
  onSubmit: (values: Product) => Promise<any>;
  fetchProduct: (id: string) => Promise<any>;
  productID: string;
  products: Product[];
  error?: boolean;
  fetching?: boolean;
}

function SignErrorDialog({title, message, ...otherProps}: {title: string; message: string}) {
  return (
    <FormDialog title={title} disableSubmit={true} initialValues={{}} onSubmit={() => {}} {...otherProps}>
      {() => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>{message}</p>
            </Grid>
          </Grid>
        );
      }
      }
    </FormDialog>
  );
}

const GetSignedEntitlements = ({onSubmit, productID, fetchProduct, products, ...otherProps}: Props) => {
  const product = products.find((p) => p.id === productID);
  if (product === undefined) {
    return <SignErrorDialog title='Product not found' message='Product not found' {...otherProps} />;
  }
  if (product.instance === undefined || product.instance == '') {
    return (
      <SignErrorDialog
        title={product.id}
        message={'Need instance to sign entitlements'}
        {...otherProps}
      />
    );
  }
  if (product.entitlements === undefined || product.entitlements.length == 0) {
    return <SignErrorDialog title={product.id} message={'No entitlements found'} {...otherProps} />;
  }

  React.useEffect(() => {
    if (fetchProduct !== undefined) {
      fetchProduct(productID);
    }
  }, []);

  return (
    <FormDialog
      title='Generate Signed Entitlements'
      initialValues={product}
      mutators={{...arrayMutators}}
      onSubmit={onSubmit}
      {...otherProps}>
      {() => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField name='productType' label='Product Type' disabled={true} />
            </Grid>
            <Grid item xs={6}>
              <TextField name='id' label='Product S/N' disabled={true} />
            </Grid>
            <Grid item xs={6}>
              <TextField name='activationKey' label='Activation Key' disabled={true}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name='description' label='Description' disabled={true}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name='instance' label='Instance' disabled={true}/>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <TextareaAutosize name='signedEntitlements' value={product.signedEntitlements} />
              </Card>
            </Grid>
          </Grid>
        );
      }}
    </FormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  return {
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: any) => {
    return dispatch(patchResource('products', values.id, values));
  },
  fetchProduct: (id: string) => fetchProductWithSignedEntitlements(id, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetSignedEntitlements);
