import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {deleteResource} from 'spectra-logic-ui/actions';
import {FormDialog, FormRenderProps} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

import {Asset} from '../common/types';

type Props = {
  onSubmit: (values: Asset) => Promise<any>;
  item: Asset;
}

const DeleteAsset = ({onSubmit, item, ...otherProps}: Props) => (
  <FormDialog
    title='Delete Asset'
    initialValues={item}
    onSubmit={onSubmit}
    closeOnSuccess {...otherProps}>
    {({}: FormRenderProps) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <p>Confirm Delete</p>
          </Grid>
        </Grid>
      );
    }}
  </FormDialog>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: Asset) => dispatch(deleteResource('assets', values.id, {})),
});

export default connect(undefined, mapDispatchToProps)(DeleteAsset);
