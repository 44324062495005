import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {patchResource} from 'spectra-logic-ui/actions';
import {FormDialog, SingleSelect} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';

import {TextField} from 'spectra-logic-ui/components';
import {ProductType, Version} from '../common/types';
import {fetchVersions} from '../common/fetch';

type Props = {
  onSubmit: (values: ProductType) => Promise<any>;
  product: ProductType;
  fetchVersions?: () => Promise<any>;
  versions: Version[];
}

const EditProductTypeDialog = ({onSubmit, product, fetchVersions, versions, ...otherProps}: Props) => (
  <FormDialog title='Edit Product Type' initialValues={product} onSubmit={onSubmit} {...otherProps}>
    {() => {
      React.useEffect(() => {
        if (fetchVersions !== undefined) {
          fetchVersions();
        }
      }, []);
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField disabled={true} name='id' label='Id' />
          </Grid>
          <Grid item xs={6}>
            <TextField disabled={true} name='name' label='Name' />
          </Grid>
          <Grid item xs={6}>
            <SingleSelect name='minimumVersion' label='Min Supported Version'
              options={versions.map((v) => v.version)}
              style={{width: '200px'}}
            />
          </Grid>
          <Grid item xs={6}>
            <SingleSelect name='currentVersion' label='Current GA Version'
              options={versions.map((v) => v.version)}
              style={{width: '200px'}}
            />
          </Grid>
        </Grid>
      );
    }}
  </FormDialog>
);

const mapStateToProps = (state: Store) => {
  const versions = state.resources['version'] || {};
  return {
    versions: versions.data || [],
    error: versions.error,
    fetching: versions.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: ProductType) => dispatch(patchResource('product_types', values.id, values)),
  fetchVersions: () => fetchVersions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProductTypeDialog);
