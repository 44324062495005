import React from 'react';
import {connect} from 'react-redux';
import arrayMutators from 'final-form-arrays';

import {Grid} from '@mui/material';

import {updateResource} from 'spectra-logic-ui/actions';
import {FormDialog} from 'spectra-logic-ui/components';
import {Dispatch, Store} from 'spectra-logic-ui';
import {TextField} from 'spectra-logic-ui/components';

import {fetchProduct, fetchProducts} from '../common/fetch';
import {Product} from '../common/types';

type Props = {
  onSubmit: (values: Product) => Promise<any>;
  fetchProduct: (id: string) => Promise<any>;
  productID: string;
  products: Product[];
  error?: boolean;
  fetching?: boolean;
}

function ChangeSNErrorDialog({title, message, ...otherProps}: {title: string; message: string}) {
  return (
    <FormDialog title={title} disableSubmit={true} initialValues={{}} onSubmit={() => {}} {...otherProps}>
      {() => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>{message}</p>
            </Grid>
          </Grid>
        );
      }
      }
    </FormDialog>
  );
}

const ChangeSerialNumber = ({onSubmit, productID, fetchProduct, products, ...otherProps}: Props) => {
  const product = products.find((p) => p.id === productID);
  if (product === undefined) {
    return <ChangeSNErrorDialog title='Product not found' message='Product not found' {...otherProps} />;
  }

  React.useEffect(() => {
    if (fetchProduct !== undefined) {
      fetchProduct(productID);
    }
  }, []);

  return (
    <FormDialog
      title='Change Serial Number'
      initialValues={product}
      mutators={{...arrayMutators}}
      onSubmit={onSubmit}
      {...otherProps}>
      {() => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField name='productType' label='Product Type' disabled={true} />
            </Grid>
            <Grid item xs={6}>
              <TextField name='activationKey' label='Activation Key' disabled={true}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name='description' label='Description' disabled={true}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name='id' label='Old Product S/N' disabled={true}/>
            </Grid>
            <Grid item xs={6}>
              <TextField name='oldID' label='Confirm old Product S/N' />
            </Grid>
            <Grid item xs={6}>
              <TextField name='newID' label='New Product S/N' />
            </Grid>
          </Grid>
        );
      }}
    </FormDialog>
  );
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  return {
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: any) => {
    const props = {
      oldID: values.oldID,
      newID: values.newID,
    };
    return dispatch(updateResource('products', values.id + '/move', props,
      {onSuccess: () => fetchProducts(dispatch)}));
  },
  fetchProduct: (id: string) => fetchProduct(id, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSerialNumber);
