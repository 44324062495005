import React from 'react';
import {connect} from 'react-redux';

import Grid from '@mui/material/Grid';

import {Dispatch, Store} from 'spectra-logic-ui';
import Card from 'spectra-logic-ui/components/card';

import {ProductType} from '../common/types';
import Table from '../common/table';
import {fetchProductTypes} from '../common/fetch';
import ButtonToolbar from '../common/button_toolbar';
import isEmpty from 'is-empty';
import EditProductDialog from './form';

type Props = {
  products?: ProductType[];
  error?: boolean;
  fetching?: boolean;
  fetchProductTypes: () => Promise<any>;
}

const ProductTypes = ({
  error = false,
  products = [],
  fetching = false,
  fetchProductTypes,
}: Props) => {
  const [selectedItem, setSelectedItem] = React.useState({} as ProductType);
  React.useEffect(() => {
    if (fetchProductTypes !== undefined) {
      fetchProductTypes();
    }
  }, []);
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <Card.Body>
              <ButtonToolbar>
                <ButtonToolbar.EditDialogButton
                  dialog={(props: any) => <EditProductDialog product={selectedItem} {...props} />}
                  disabled={isEmpty(selectedItem)}
                />
              </ButtonToolbar>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Product Type</Table.Cell>
                    <Table.Cell>Minimum Supported Version</Table.Cell>
                    <Table.Cell>Current GA Version</Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body isLoading={fetching} hasError={error}>
                  {products && products.map((item) => (
                    <Table.Row
                      key={item.id} selected={item.id === selectedItem.id}
                      onClick={() => setSelectedItem(item)}
                    >
                      <Table.Cell>{item.id && item.id.charAt(0).toUpperCase() + item.id.slice(1)}</Table.Cell>
                      <Table.Cell>{item.minimumVersion}</Table.Cell>
                      <Table.Cell>{item.currentVersion}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['product_types'] || {};
  return {
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    fetchProductTypes: () => fetchProductTypes(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTypes);
