import {connect} from 'react-redux';

import {createResource} from 'spectra-logic-ui/actions';
import {Dispatch, Store} from 'spectra-logic-ui';

import {fetchVersions} from '../common/fetch';
import ProductForm from './form';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: any) => {
    const {accounts='', ...otherValues} = values;
    let accountsArray = accounts.split(/[\s,]+/);
    accountsArray = accountsArray.map((a: string) => a.trim()).filter((a: string) => a != '');
    if (otherValues.data !== undefined && otherValues.data === '') {
      delete(otherValues.data);
    }
    return dispatch(createResource('products', {accounts: accountsArray, ...otherValues}));
  },
  fetchVersions: () => fetchVersions(dispatch),
});

const mapStateToProps = (state: Store) => {
  const uiState = state.ui || {};
  const versions = state.resources['version'] || {};
  return {
    production: uiState['development'] ? !uiState['development'] : true,
    versions: versions.data || [],
    error: versions.error,
    fetching: versions.fetching,
    method: 'new',
    visibleList: [
      'productType',
      'id',
      'accounts',
      'activationKey',
      'instance',
      'data',
      'holdVersion',
      'availableVersion',
      'branch',
      'description',
      'entitlements',
    ],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
