/*
 * ****************************************************************************
 *   Copyright 2014-2019 Spectra Logic Corporation. All Rights Reserved.
 * ***************************************************************************
 */

import React from 'react';

import ComputerIcon from '@mui/icons-material/Computer';
import HomeIcon from '@mui/icons-material/Home';
import ReportsIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HelpIcon from '@mui/icons-material/Help';
import KeyIcon from '@mui/icons-material/Key';

import App from 'spectra-logic-ui/app';
import SettingsMenu from 'spectra-logic-ui/app/header/settings_menu';

import About from './about';
import Assets from './assets';
import Dashboard from './dashboard';
import CodeVersions from './products';
import ProductTypes from './product_types';
import PublicKeys from './public_keys';
import BucketRoles from './bucket_roles';
import LoginPage from './login';

// State that comes from the server via index.html.
const initialState = (window as any).SPECTRA_LOGIC_LS_INITIAL_STATE || {};

// Array of Material-UI <ListItem /> properties
const nav = [
  {text: 'Dashboard', icon: HomeIcon, path: '/dashboard'},
  {text: 'Product Installs', icon: ComputerIcon, path: '/products'},
  {text: 'Assets', icon: ReportsIcon, path: '/assets'},
];

const settings = [
  {text: 'Product Types', icon: AccountBalanceIcon, path: '/product_types'},
  {text: 'Bucket Roles', icon: HelpIcon, path: '/bucket_roles'},
  {text: 'Public Keys', icon: KeyIcon, path: '/public_keys'},
  {text: 'About', icon: HelpIcon, path: '/about'},
];

const routes = [
  {path: '/', component: Dashboard},
  {path: '/dashboard', component: Dashboard},
  {path: '/products', component: CodeVersions},
  {path: '/product_types', component: ProductTypes},
  {path: '/public_keys', component: PublicKeys},
  {path: '/bucket_roles', component: BucketRoles},
  {path: '/about', component: About},
  {path: '/assets', component: Assets},
];

const additionalHeader = (
  <div style={ {display: 'inline'} }>
    <SettingsMenu settings={ settings} />
  </div>
);

// Create and run the app
const app = new App({
  title: 'Software Licensing',
  subTitle: '',
  nav: nav,
  routes: routes,
  initialState: {ui: initialState},
  additionalHeader: additionalHeader,
  loginComponent: LoginPage,
  authEnabled: true,
});
app.run();

