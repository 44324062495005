import React from 'react';
import {Card, FormDialog, SingleSelect, TextField, Tooltip} from 'spectra-logic-ui/components';
import {SelectOption} from 'spectra-logic-ui';
import {Grid} from '@mui/material';
import arrayMutators from 'final-form-arrays';
import {Product, Version} from '../common/types';
import {Entitlements} from './entitlements';

const HoldVersionTooltip = () => {
  return (
    <Tooltip>
      Hold version represents the newest version a product install will be limited to. This is used
      to keep a product install at a version that's older than generally available. For example,
      it could be used to keep a customer at an older version because their support contract expired.
      It could also be used to install an older version of a product because their environment doesn't
      support the latest version. Leave this field blank if you want to always use the latest, generally
      available version. This field should not be used at the same time as available version.
    </Tooltip>
  );
};

const AvailableVersionTooltip = () => {
  return (
    <Tooltip>
      Available version represents the newest version a product install
      can use. This is typically used for product installs that require a version that's newer
      than generally available. For example, this could be used for beta or release candidate versions.
      It could also be used to release a new version to a limited number of customers.
      Leave this field blank if you want to always use the latest, generally available version.
      The generally available version will be used if it's higher than this available version.
      This field should not be used at the same time as hold version.
    </Tooltip>
  );
};

type Props = {
  onSubmit: (values: Product) => Promise<any>;
  fetchProduct: (id: string) => Promise<any>;
  productID: string;
  versions?: Version[];
  products: Product[];
  error?: boolean;
  fetching?: boolean;
  production: boolean;
  method: string;
  disabledList: string[];
  visibleList: string[];
}

const ProductForm = ({
  onSubmit,
  fetchProduct,
  productID,
  products,
  production,
  method,
  disabledList = [],
  visibleList = [],
  ...otherProps
}: Props) => {
  const isDisabled = function(fieldName: string) {
    return disabledList.includes(fieldName);
  };
  const isVisible = function(fieldName: string) {
    return visibleList.includes(fieldName);
  };

  let title = 'New Product';
  let initialProduct = {};
  if (method === 'edit') {
    title = 'Edit Product';
    const product = products.find((p) => p.id === productID);
    if (product === undefined) {
      return (
        <FormDialog title='Edit Product' initialValues={{}} onSubmit={onSubmit} {...otherProps}>
          {() => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField name='productType' label='Product Type' disabled={true}/>
                </Grid>
              </Grid>
            );
          }
          }
        </FormDialog>
      );
    }

    const {accounts, ...otherProductProps} = product;
    const accountsStr = (accounts || []).join('\n');
    initialProduct = {accounts: accountsStr, ...otherProductProps};

    React.useEffect(() => {
      if (fetchProduct !== undefined) {
        fetchProduct(productID);
      }
    }, []);
  }

  return (
    <FormDialog
      title={title}
      initialValues={initialProduct}
      onSubmit={onSubmit}
      mutators={{...arrayMutators}}
      {...otherProps}>
      {() => {
        const productOptions = [
          {key: 'vail', text: 'Vail'},
          {key: 'storcycle', text: 'Storcycle'},
        ] as SelectOption[];
        return (
          <Grid container spacing={2}>
            {isVisible('productType') && <Grid item xs={6}>
              <SingleSelect
                name='productType'
                label='Product Type'
                options={productOptions}
                style={{width: '200px'}}
                disabled={isDisabled('productType')}
              />
            </Grid> }
            {isVisible('id') && <Grid item xs={6}>
              <TextField name='id' label='Product S/N'
                disabled={isDisabled('id')} />
            </Grid> }
            {isVisible('accounts') && <Grid item xs={6}>
              <TextField
                name='accounts'
                label='AWS Accounts'
                fullWidth
                multiline
                rows={2}
                variant='outlined'
                disabled={isDisabled('accounts')}
              />
            </Grid> }
            {isVisible('activationKey') && <Grid item xs={6}>
              <TextField name='activationKey' label='Activation Key'
                disabled={isDisabled('activationKey')} />
            </Grid> }
            {isVisible('description') && <Grid item xs={6}>
              <TextField name='description' label='Description' disabled={isDisabled('description')}/>
            </Grid> }
            {isVisible('instance') && <Grid item xs={6}>
              <TextField name='instance' label='Instance' disabled={isDisabled('instance')}/>
            </Grid> }
            {isVisible('data') && <Grid item xs={6}>
              <TextField name='data' label='Instance Data' disabled={isDisabled('data')}/>
            </Grid> }
            {isVisible('holdVersion') && <Grid item xs={6}>
              <TextField name='holdVersion' label='Hold Version' disabled={isDisabled('holdVersion')}/>
              <HoldVersionTooltip />
            </Grid> }
            {isVisible('availableVersion') && <Grid item xs={6}>
              <TextField name='availableVersion' label='Available Version' disabled={isDisabled('availableVersion')}/>
              <AvailableVersionTooltip />
            </Grid> }
            {isVisible('currentVersion') && <Grid item xs={6}>
              <TextField name='currentVersion' label='Current Version' disabled={isDisabled('currentVersion')} />
            </Grid> }
            {isVisible('lowestVersion') && <Grid item xs={6}>
              <TextField name='lowestVersion' label='Lowest Node Version' disabled={isDisabled('lowestVersion')}/>
            </Grid> }
            {(!production && isVisible('branch')) &&
                <Grid item xs={6}>
                  <TextField name='branch' label='Branch' disabled={isDisabled('branch')}/>
                </Grid>
            }
            {isVisible('publicKey') && <Grid item xs={6}>
              <TextField name='publicKey' label='Public Key' disabled={isDisabled('publicKey')}/>
            </Grid> }
            {isVisible('entitlements') && <Grid item xs={12}>
              <Card>
                <Entitlements />
              </Card>
            </Grid> }
          </Grid>
        );
      }}
    </FormDialog>
  );
};

export default ProductForm;
