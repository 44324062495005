import {
  IconButton, MenuItem, Select as MaterialSelect,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField as MaterialTextField,
  Typography,
} from '@mui/material';
import {Field} from 'react-final-form';
import React, {ChangeEvent} from 'react';
import DeleteIcon from 'spectra-logic-ui/icons/Delete';
import AddIcon from 'spectra-logic-ui/icons/Add';
import makeStyles from '@mui/styles/makeStyles';
import {SpectraLogoColor} from 'spectra-logic-ui/colors';
import {FieldArray} from 'react-final-form-arrays';
import {SelectChangeEvent} from '@mui/material/Select';


const useStyles = makeStyles({
  textField: {
    marginTop: 12,
  },
  root: {
    '& > *': {
      paddingTop: 0,
      paddingBottom: 5,
    },
  },
  typography: {
    marginLeft: '10px',
  },
  card: {
    marginBottom: 5,
  },
  checkbox: {
    marginLeft: 0,
  },
  mtu: {
    '& > *': {
      width: '30%',
    },
  },
  plus: {
    color: SpectraLogoColor.GREEN,
  },
  div: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
});


export const Froms = ({fields, disabled, meta: {error, submitFailed}}: any) => {
  const classes = useStyles();

  const renderRow = (name: string, index: number) => {
    return (
      <TableRow key={name}>
        <Field name={name}>
          {({input, meta: {touched, invalid}}: any) => {
            const onChange = (event: ChangeEvent<HTMLInputElement>) => {
              input.onChange(event.target.value);
            };

            return (
              [
                <TableCell>
                  <MaterialTextField
                    value={input.value}
                    fullWidth onChange={onChange} error={touched && invalid}
                    helperText={touched && invalid ? ' ' : null} variant='standard'
                    disabled={disabled}
                  />
                </TableCell>,
              ]
            );
          }}
        </Field>
        <TableCell>
          <IconButton disabled={disabled} onClick={() => fields.remove(index)} size='large'>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <div className={classes.div}>
        <Typography className={classes.typography}>
                    From Versions
        </Typography>
        <IconButton
          className={classes.plus}
          onClick={() => {
            fields.push('');
          }}
          disabled={disabled}
          size='large'>
          <AddIcon />
        </IconButton>
      </div>
      <Table>
        <TableHead>
          {submitFailed && error && <span>{error}</span>}
        </TableHead>
        <TableBody>
          {fields.map(renderRow)}
        </TableBody>
      </Table>
    </div>
  );
};

export const Fors = ({osList}: any) => {
  const classes = useStyles();

  return (
    <div>
      <FieldArray name='forOS'>
        {({fields}) =>
          [<div className={classes.div}>
            <Typography className={classes.typography}>
              For Versions
            </Typography>
            <IconButton
              className={classes.plus}
              onClick={() => {
                fields.push('');
              }}
              size='large'>
              <AddIcon />
            </IconButton>
          </div>,
          <Table>
            <TableBody>
              {fields.map((name, index) => (
                <TableRow key={name}>
                  <Field name={name}>
                    {({input, meta: {touched, invalid}}: any) => {
                      const v = input.value['version'];
                      const n = input.value['name'];
                      let ret: { [key: string]: string } = {name: n, version: v};


                      const onVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
                        ret = {name: ret.name, version: event.target.value};
                        input.onChange(ret);
                      };
                      const onOsChange = (event: SelectChangeEvent) => {
                        ret = {name: event.target.value, version: ret.version};
                        input.onChange(ret);
                      };

                      return (
                        [
                          <TableCell>
                            <MaterialSelect
                              onChange={onOsChange}
                              value={n}
                            >
                              {osList.map(function(os: string) {
                                return (
                                  <MenuItem value={os}>{os}</MenuItem>
                                );
                              })}
                            </MaterialSelect>
                          </TableCell>,
                          <TableCell>
                            <MaterialTextField
                              fullWidth
                              value={v}
                              onChange={onVersionChange}
                              error={touched && invalid}
                              helperText={touched && invalid ? ' ' : null} variant='standard'
                            />
                          </TableCell>,
                        ]
                      );
                    }}
                  </Field>
                  <TableCell>
                    <IconButton onClick={() => fields.remove(index)} size='large'>
                      <DeleteIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>]
        }
      </FieldArray>
    </div>
  );
};
