import React from 'react';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {Button} from '@mui/material';

import {Dispatch, Store, SessionState} from 'spectra-logic-ui';

import {login} from '../common/fetch';

type Props = {
  session: SessionState;
  authenticated: boolean;
  error?: boolean;
  fetching: boolean;
  onForgotPassword: Function;
  samlLogin: () => any;
}

const LoginPage = ({authenticated, samlLogin, error}: Props) => {
  return (
    <div>
      {authenticated && <Navigate to='/dashboard' replace={true}/>}
      {error && <p>Error logging in</p>}
      <a href='/api/login'>Login with SSO</a>
      <Button onClick={samlLogin}>
        Proceed to Application
      </Button>
    </div>
  );
};

const mapStateToProps = (state: Store) => {
  const session = state['session'] || {};
  return {
    authenticated: session.authenticated,
    error: session.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    samlLogin: () => login(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
