import React from 'react';
import {Link} from 'react-router-dom';

import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {TableBodyProps as MuiTableBodyProps} from '@mui/material/TableBody';
import {TableCellProps as MuiTableCellProps} from '@mui/material/TableCell';
import {TableRowProps as MuiTableRowProps} from '@mui/material/TableRow';

import {Color} from 'spectra-logic-ui/colors';
import {LoadingErrorRow, LoadingRow} from 'spectra-logic-ui/components';
import {flatten} from 'spectra-logic-ui/helpers/children';

export interface TableCellProps extends MuiTableCellProps {
  children?: React.ReactNode;
  className?: string;
  colSpan?: number;
}

export interface TableCellLinkProps {
  children: any;
  link: string;
  onClick?: (...args: any) => void;
}

export interface TableCellButtonProps {
  icon: any;
  onClick: (...args: any) => void;
  size?: 'small' | 'medium';
  tooltip?: string;
  IconProps?: object;
  children?: never;
}

export interface TableRowProps extends MuiTableRowProps {
  onClick?: (...args: any) => void;
  children?: React.ReactNode;
  className?: string;
}

export interface TableBodyProps extends MuiTableBodyProps {
  isLoading?: boolean;
  hasError?: boolean;
  children?: React.ReactNode;
  className?: string;
  colSpan?: number;
}

export interface TableHeaderProps {
  children: React.ReactElement<TableRowProps> | React.ReactElement<TableRowProps>[];
  colSpan?: number;
}

export interface TableProps {
  children: React.ReactElement<TableHeaderProps | TableBodyProps> |
    React.ReactElement<TableHeaderProps | TableBodyProps>[];
  size?: 'small' | 'medium';
}

const useStyles = makeStyles({
  tableCell: {
    wordBreak: 'break-word',
  },
  tableCellLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableCellButton: {
    textAlign: 'right',
    paddingTop: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingRight: 3,
    },
  },
  tableRowNoBorder: {
    '& > *': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  tableRowError: {
    '& > *': {
      color: Color.ERROR,
    },
  },
  tableHeader: {
    '& > * > *': {
      color: Color.GRAY_DARK,
    },
  },
  notFoundText: {
    color: Color.GRAY_DARK,
  },
  pointer: {
    cursor: 'pointer',
  },
});

const Table = ({children, ...props}: TableProps) => (
  <MuiTable {...props}>{children}</MuiTable>
);

Table.Header = ({children, ...props}: TableHeaderProps) => {
  const classes = useStyles();
  return (
    <MuiTableHead className={classes.tableHeader} {...props}>{children}</MuiTableHead>
  );
};

Table.Body = ({children, isLoading, hasError, className, colSpan, ...props}: TableBodyProps) => {
  const filteredChildren = flatten(children);
  const classes = useStyles();

  if (hasError) {
    return (
      <MuiTableBody>
        <LoadingErrorRow rowProps={{className: `${classes.tableRowError} ${classes.tableRowNoBorder}`}} />
      </MuiTableBody>
    );
  } else if (React.Children.count(filteredChildren) === 0) {
    // Only render a loading message if there are no children so the table
    // doesn't flicker if it's already been rendered with children before.
    if (isLoading) {
      return (
        <MuiTableBody>
          <LoadingRow rowProps={{className: classes.tableRowNoBorder}}/>
        </MuiTableBody>
      );
    }
    return (
      <MuiTableBody>
        <MuiTableRow className={classes.tableRowNoBorder}>
          <MuiTableCell colSpan={colSpan}>
            <Typography className={classes.notFoundText}>No items to display.</Typography>
          </MuiTableCell>
        </MuiTableRow>
      </MuiTableBody>
    );
  }
  return <MuiTableBody className={className} {...props}>{filteredChildren}</MuiTableBody>;
};

Table.Row = ({children, className, onClick, ...props}: TableRowProps) => {
  const classes = useStyles();
  return (
    <MuiTableRow className={onClick ? `${classes.pointer} ${className}` : className} onClick={onClick} {...props}>
      {children}
    </MuiTableRow>
  );
};

Table.Cell = ({children, className, ...props}: TableCellProps) => {
  const classes = useStyles();
  return (
    <MuiTableCell className={`${classes.tableCell} ${className}`} {...props}>{children}</MuiTableCell>
  );
};

Table.CellLink = ({children, link, onClick}: TableCellLinkProps) => {
  const classes = useStyles();
  return (
    <MuiTableCell>
      <Link to={link} onClick={onClick} className={classes.tableCellLink}>
        {children}
      </Link>
    </MuiTableCell>
  );
};

Table.CellButton = ({icon: Icon, tooltip, size = 'medium', IconProps, onClick}: TableCellButtonProps) => {
  const classes = useStyles();
  const button = (
    <IconButton size={size} onClick={onClick}>
      <Icon {...IconProps} />
    </IconButton>
  );
  return (
    <MuiTableCell className={classes.tableCellButton}>
      {tooltip ?
        <Tooltip title={tooltip}>
          {button}
        </Tooltip> :
        button
      }
    </MuiTableCell>
  );
};

export default Table;
