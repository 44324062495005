import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {PublicKey} from '../common/types';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog, FormRenderProps, TextField} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

type Props = {
  onSubmit: (values: PublicKey) => Promise<any>;
  publicKey: PublicKey;
}

const NewPublicKeyDialog = ({onSubmit, publicKey, ...otherProps}: Props) => (
  <FormDialog title='Add Public Key' initialValues={publicKey} onSubmit={onSubmit} {...otherProps}>
    {({}: FormRenderProps) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField name='id' label='Key ID' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField name='value' label='Value' fullWidth multiline rows={12} variant='outlined' />
          </Grid>
        </Grid>
      );
    }}
  </FormDialog>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: PublicKey) => dispatch(createResource('public_key', values)),
});

export default connect(undefined, mapDispatchToProps)(NewPublicKeyDialog);
