import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';

import {BucketRole} from '../common/types';

import {createResource} from 'spectra-logic-ui/actions';
import {FormDialog, FormRenderProps, TextField} from 'spectra-logic-ui/components';
import {Dispatch} from 'spectra-logic-ui';

type Props = {
  onSubmit: (values: BucketRole) => Promise<any>;
  item: BucketRole;
}

const NewBucketRoleDialog = ({onSubmit, item, ...otherProps}: Props) => (
  <FormDialog title='Add Bucket Role' initialValues={item} onSubmit={onSubmit} {...otherProps}>
    {({}: FormRenderProps) => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField name='id' label='Bucket Name' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField name='value' label='Role ARN' fullWidth />
          </Grid>
        </Grid>
      );
    }}
  </FormDialog>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: BucketRole) => dispatch(createResource('bucket_role', values)),
});

export default connect(undefined, mapDispatchToProps)(NewBucketRoleDialog);
