import React, {memo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {isEqual} from 'lodash';
import isEmpty from 'is-empty';

import Grid from '@mui/material/Grid';
import {Edit as EditIcon} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';

import {Dispatch, Store} from 'spectra-logic-ui';
import {Card, SlidePanel} from 'spectra-logic-ui/components';
import {dateTimeShort} from 'spectra-logic-ui/helpers/date';
import OpenSlidePanelIcon from 'spectra-logic-ui/icons/Launch';

import {Product} from '../common/types';
import Table from '../common/table';
import {fetchProducts} from '../common/fetch';
import ButtonToolbar from '../common/button_toolbar';
import DeleteDialog from '../common/delete_dialog';
import NewProduct from './new';
import EditProduct from './edit';
import AdvancedEdit from './edit_advanced';
import Properties from './properties';
import EditProductEntitlements from './edit_entitlements';
import GetSignedEntitlements from './get_signed_entitlements';
import ChangeSerialNumber from './change_serial_number';
import EntitlementsSlider from './entitlements_slider';
import AssetLinks from './asset_links';

type Props = {
  products?: Product[];
  error?: boolean;
  fetching?: boolean;
  production: boolean;
  fetchProducts?: () => Promise<any>;
}

const useTableStyles = makeStyles({
  detailsIcon: {
    height: 20,
    width: 20,
  },
});

const Products = ({products = [], error = false, fetching = false, production, fetchProducts}: Props) => {
  const [selectedProductID, setSelectedProductID] = useState('');
  const clearSelectedProductID = () => {
    setSelectedProductID('');
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  useEffect(() => {
    if (fetchProducts !== undefined) {
      fetchProducts();
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <Card.Body>
              <ButtonToolbar>
                <ButtonToolbar.CreateDialogButton dialog={NewProduct} />
                <ButtonToolbar.EditDialogButton
                  dialog={(props: any) => <EditProduct productID={selectedProductID} {...props} />}
                  disabled={isEmpty(selectedProductID)}
                />
                <ButtonToolbar.DialogButton
                  dialog={(props: any) => <EditProductEntitlements productID={selectedProductID} {...props} />}
                  title='Entitlements'
                  color='secondary'
                  icon={EditIcon}
                  disabled={isEmpty(selectedProductID)}
                />
                <ButtonToolbar.DialogButton
                  dialog={(props: any) => <AdvancedEdit productID={selectedProductID} {...props} />}
                  title='Advanced Edit'
                  color='secondary'
                  icon={EditIcon}
                  disabled={isEmpty(selectedProductID)}
                />
                <ButtonToolbar.DialogButton
                  dialog={(props: any) => <GetSignedEntitlements productID={selectedProductID} {...props} />}
                  title='Generate Signed Entitlements'
                  color='secondary'
                  icon={EditIcon}
                  disabled={isEmpty(selectedProductID)}
                />
                <ButtonToolbar.DialogButton
                  dialog={(props: any) => <ChangeSerialNumber productID={selectedProductID} {...props} />}
                  title='Change S/N'
                  color='secondary'
                  icon={EditIcon}
                  disabled={isEmpty(selectedProductID)}
                />
                <ButtonToolbar.DeleteDialogButton
                  disabled={isEmpty(selectedProductID)} onSuccess={clearSelectedProductID}
                  dialog={(props: any) => (
                    <DeleteDialog
                      id={selectedProductID} name={selectedProductID} resource='products' {...props}
                    />
                  )}
                />
              </ButtonToolbar>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.Cell>Type</Table.Cell>
                    <Table.Cell>Description</Table.Cell>
                    <Table.Cell>S/N</Table.Cell>
                    <Table.Cell>Activation Key</Table.Cell>
                    <Table.Cell>Available Version</Table.Cell>
                    <Table.Cell>Hold Version</Table.Cell>
                    {!production && <Table.Cell>Branch</Table.Cell>}
                    <Table.Cell>Last Accessed</Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </Table.Header>
                <Table.Body isLoading={fetching} hasError={error}>
                  {products.map((product) => (
                    <ProductRow key={product.id} product={product} openDrawer={openDrawer} production={production}
                      selected={product.id === selectedProductID} setSelectedProductID={setSelectedProductID} />
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
        </Grid>
      </Grid>
      {selectedProductID !== '' && <Panel
        selectedProductID={selectedProductID} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer}
      />}
    </>
  );
};

type ProductRowProps = {
  openDrawer: (...args: any) => any;
  product: Product;
  production: boolean;
  selected: boolean;
  setSelectedProductID: (...args: any) => any;
};

// rowPropsAreEqual is a React memoization function for the ProductRow component that
// determines if anything has changed that would require the component to be re-rendered.
const rowPropsAreEqual = (prevProps: ProductRowProps, nextProps: ProductRowProps) => {
  return isEqual(prevProps.product, nextProps.product) && prevProps.selected === nextProps.selected;
};

// ProductRow represents a single row in the products table. React.memo is used to
// increase the performance of the table by preventing re-rendering of all rows when
// a user selects a new one (which causes the parent table to be re-rendered).
const ProductRow = memo((
  {openDrawer, product, production, selected, setSelectedProductID}: ProductRowProps,
) => {
  return (
    <Table.Row key={product.id} selected={selected} onClick={() => setSelectedProductID(product.id)}>
      <Table.Cell>{product.productType}</Table.Cell>
      <Table.Cell>{product.description}</Table.Cell>
      <Table.Cell>{product.id}</Table.Cell>
      <Table.Cell>{product.activationKey}</Table.Cell>
      <Table.Cell>{product.availableVersion}</Table.Cell>
      <Table.Cell>{product.holdVersion}</Table.Cell>
      {!production && <Table.Cell>{product.branch}</Table.Cell>}
      <Table.Cell>{dateTimeShort(product.lastAccessed)}</Table.Cell>
      <Table.CellButton icon={DetailsIcon} tooltip='View Details' onClick={openDrawer} />
    </Table.Row>
  );
}, rowPropsAreEqual);

type PanelProps = {
  selectedProductID: string;
  isDrawerOpen: boolean;
  closeDrawer: (...args: any) => any;
};

const Panel = ({selectedProductID, isDrawerOpen, closeDrawer}: PanelProps) => {
  // const title = selectedUser.username ? selectedUser.username : selectedGroup.name;
  const title = selectedProductID;
  const options = ['Properties', 'Entitlements', 'Assets'];

  return (
    <SlidePanel title={title} options={options} open={isDrawerOpen} onClose={closeDrawer}>
      <Properties item={selectedProductID} />
      <EntitlementsSlider item={selectedProductID} />
      <AssetLinks item={selectedProductID} />
    </SlidePanel>
  );
};

const DetailsIcon = (props: any) => {
  const classes = useTableStyles();
  return <OpenSlidePanelIcon className={classes.detailsIcon} {...props} />;
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  const uiState = state.ui || {};
  return {
    production: uiState['development'] ? !uiState['development'] : true,
    products: products.data || [],
    error: products.error,
    fetching: products.fetching,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    fetchProducts: () => fetchProducts(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
