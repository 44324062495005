import React from 'react';
import {connect} from 'react-redux';

import {Grid} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Card from 'spectra-logic-ui/components/card';
import {Dispatch, Store} from 'spectra-logic-ui';

import {fetchProducts, fetchAssets, fetchProductTypes} from '../common/fetch';
import {Product, Asset, ProductType} from '../common/types';

type Props = {
  products?: Product[];
  assets?: Asset[];
  productTypes?: ProductType[];
  fetchProducts?: () => Promise<any>;
  fetchProductTypes: () => Promise<any>;
  fetchAssets?: () => Promise<any>;
}

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    paddingBottom: 5,
  },
  grid: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  gridHeader: {
    fontWeight: 'bold',
    textAlign: 'left',
    marginRight: 10,
  },
  gridData: {
    wordBreak: 'break-all',
  },
});

const Dashboard =
({products = [],
  assets = [],
  productTypes = [],
  fetchAssets,
  fetchProducts,
  fetchProductTypes}: Props) => {
  React.useEffect(() => {
    if (fetchProducts !== undefined && fetchAssets !== undefined) {
      fetchProducts().then(() => fetchAssets().then(() => fetchProductTypes()));
    }
  }, []);
  const classes = useStyles();
  console.log(productTypes);
  const vailProductType = productTypes.filter(function(pt) {
    return pt.id == 'vail';
  });
  console.log(vailProductType);
  const vailVersion = vailProductType.length == 1 ? vailProductType[0].currentVersion : '';
  console.log(vailVersion);
  const scProductType = productTypes.filter(function(pt) {
    return pt.id == 'storcycle';
  });
  const scVersion = scProductType.length == 1 ? scProductType[0].currentVersion : '';

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card>
          <Card.Body>
            <Grid container className={classes.grid}>
              <Grid item xs={6} className={classes.gridHeader}><h2>Vail</h2></Grid>

              <Grid item xs={6} className={classes.gridHeader}>Installs</Grid>
              <Grid item xs={5}>
                <div className={classes.gridData}>
                  {
                    products.filter(function(product) {
                      return product.productType == 'vail';
                    }).length
                  }
                </div>
              </Grid>

              <Grid item xs={6} className={classes.gridHeader}>Assets</Grid>
              <Grid item xs={5}>
                <div className={classes.gridData}>
                  {
                    assets.filter(function(asset) {
                      return asset.productType == 'vail';
                    }).length
                  }
                </div>
              </Grid>

              <Grid item xs={6} className={classes.gridHeader}>GA Version</Grid>
              <Grid item xs={5}>
                <div className={classes.gridData}>
                  {vailVersion}
                </div>
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <Card.Body>
            <Grid container className={classes.grid}>
              <Grid item xs={6} className={classes.gridHeader}><h2>StorCycle</h2></Grid>

              <Grid item xs={6} className={classes.gridHeader}>Installs</Grid>
              <Grid item xs={5}>
                <div className={classes.gridData}>
                  {
                    products.filter(function(product) {
                      return product.productType == 'storcycle';
                    }).length
                  }
                </div>
              </Grid>

              <Grid item xs={6} className={classes.gridHeader}>Assets</Grid>
              <Grid item xs={5}>
                <div className={classes.gridData}>
                  {
                    assets.filter(function(asset) {
                      return asset.productType == 'storcycle';
                    }).length
                  }
                </div>
              </Grid>

              <Grid item xs={6} className={classes.gridHeader}>GA Version</Grid>
              <Grid item xs={5}>
                <div className={classes.gridData}>
                  {scVersion}
                </div>
              </Grid>
            </Grid>
          </Card.Body>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: Store) => {
  const products = state.resources['products'] || {};
  const productTypes = state.resources['product_types'] || {};
  const assets = state.resources['assets'] || {};
  return {
    products: products.data || [],
    assets: assets.data || [],
    productTypes: productTypes.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return ({
    fetchProducts: () => fetchProducts(dispatch),
    fetchAssets: () => fetchAssets(dispatch),
    fetchProductTypes: () => fetchProductTypes(dispatch),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
