import {Dispatch} from 'spectra-logic-ui';
import {fetchResource, login as spectraLogin} from 'spectra-logic-ui/actions';

export const fetchOss = (dispatch: Dispatch) => {
  return dispatch(fetchResource('os'));
};

export const fetchVersions = (dispatch: Dispatch) => {
  return dispatch(fetchResource('version'));
};

export const fetchPublicKeys = (dispatch: Dispatch) => {
  return dispatch(fetchResource('public_key'));
};

export const fetchBucketRoles = (dispatch: Dispatch) => {
  return dispatch(fetchResource('bucket_role'));
};

export const fetchProducts = (dispatch: Dispatch) => {
  return dispatch(fetchResource('products'));
};

export const fetchProduct = (id: string, dispatch: Dispatch) => {
  return dispatch(fetchResource('products', id));
};

export const fetchProductWithSignedEntitlements = (id: string, dispatch: Dispatch) => {
  return dispatch(fetchResource('products', id, {query: {includeSignedEntitlements: 'true'}}));
};

export const fetchProductWithAssetLinks = (id: string, dispatch: Dispatch) => {
  return dispatch(fetchResource('products', id, {query: {includeAssetLinks: 'true'}}));
};

export const fetchProductTypes = (dispatch: Dispatch) => {
  return dispatch(fetchResource('product_types'));
};

export const fetchAbout = (dispatch: Dispatch) => {
  return dispatch(fetchResource('about'));
};

export const fetchAssets = (dispatch: Dispatch) => {
  return dispatch(fetchResource('assets'));
};

export const login = (dispatch: Dispatch) => {
  return dispatch(spectraLogin({}));
};
