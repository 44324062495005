import {connect} from 'react-redux';

import {createResource} from 'spectra-logic-ui/actions';
import {Dispatch} from 'spectra-logic-ui';

import {Asset} from '../common/types';
import AssetForm from './form';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: Asset) => dispatch(createResource('assets', values)),
});

export default connect(null, mapDispatchToProps)(AssetForm);
