import {connect} from 'react-redux';

import {patchResource} from 'spectra-logic-ui/actions';
import {Dispatch} from 'spectra-logic-ui';

import {Asset} from '../common/types';
import AssetForm from './form';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (values: Asset) => dispatch(patchResource('assets', values.id, values)),
  disabledList: ['productType', 'version', 'branch', 'fileName', 'fileType'],
});


export default connect(null, mapDispatchToProps)(AssetForm);
